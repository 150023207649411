export function onSupportLinkClicked(){
    let suportBtn = document.querySelector(".suporte-action") as HTMLDivElement;
    let helpContainer = document.querySelector(".help-container") as HTMLDivElement;
    let closerHelpContainer = helpContainer.querySelector(".help-container-closer") as HTMLDivElement;


    suportBtn.addEventListener("click",()=>{
        helpContainer.classList.toggle("show");
    });

    closerHelpContainer.addEventListener("click",()=>{
        helpContainer.classList.remove("show");
    })
}