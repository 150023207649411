import swal from "sweetalert";
import { IHttpRequestResponse, badRequestHandle, displayErrorsFeedback, formErrorSpreadObj, httpRequest } from "./HttpRequest";
import { showModal } from "./modalCtrl";

interface IClaimsCreated {
  ID: number
  TrackID: string
  EditKey: string
  FaseCode: number
  FaseName: string
  CreationDate:string
}

export function denunciaModalCtrl(){
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //const regexValidateNIA = /^\d{1,2}\.\d{6}\.\d{6,}$/;

    let concelhosListObjArr = [
        {
            value:"Boavista",
            label:"Boa vista"
        },
        {
            value:"Brava",
            label:"Brava"
        },
        {
            value:"Maio",
            label:"Maio"
        },
        {
            value:"Mosteiros",
            label:"Mosteiros"
        },
        {
            value:"Paul",
            label:"Paul"
        },
        {
            value:"Porto Novo",
            label:"Porto Novo"
        },
        {
            value:"Praia",
            label:"Praia"
        },
        {
            value:"Ribeira Brava",
            label:"Ribeira Brava"
        },
        {
            value:"Ribeira Grande",
            label:"Ribeira Grande de Santo Antão"
        },
        {
            value:"Ribeira Grande Santiago",
            label:"Ribeira Grande de Santiago"
        },
        {
            value:"Sal",
            label:"Sal"
        },
        {
            value:"Santa Catarina",
            label:"Santa Catarina de Santiago"
        },
        {
            value:"Santa Catarina Fogo",
            label:"Santa Catarina do Fogo"
        },
        {
            value:"Santa Cruz",
            label:"Santa Cruz"
        },
        {
            value:"Sao Domingos",
            label:"São Domingos"
        },
        {
            value:"Sao Felipe",
            label:"São Felipe"
        },
        {
            value:"Sao Lourenco dos Orgaos",
            label:"São Lourenço dos Órgãos"
        },
        {
            value:"Sao Miguel",
            label:"São Miguel"
        },
        {
            value:"Sao Salvador do Mundo",
            label:"São Salvador do Mundo"
        },
        {
            value:"Sao Vicente",
            label:"São Vicente"
        },
        {
            value:"Tarrafal Santiago",
            label:"Tarrafal de Santiago"
        },
        {
            value:"Tarrafal Sao Nicolau",
            label:"Tarrafal de São Nicolau"
        }
    ];

    let validsConcelhos = concelhosListObjArr.map(el=>el.value);

    let previousOkayArr:string[] = []
    let previousErrorArr:string[] = []

    let allElementWithModalDenunciaRef = document.querySelectorAll<HTMLElement>(`[data-modal="modal-denuncia"]`);
    let modalDenuncia = document.querySelector<HTMLDivElement>("#modal-denuncia")
    let formStepContainer = modalDenuncia?.querySelector<HTMLFormElement>(".form-container-step");
    let loaderFormSubmited = modalDenuncia?.querySelector<HTMLDivElement>(".loader-container");

    let containerAnonymos = modalDenuncia?.querySelector<HTMLDivElement>(".anonymous-container");
    let btnSetDenuncianteInfo = containerAnonymos?.querySelector<HTMLButtonElement>(".btn-set-info");

    let inputTypeDenuncia = formStepContainer?.querySelector<HTMLInputElement>(`[name="TypeD"]`);
    let inputNomeDenunciate = formStepContainer?.querySelector<HTMLInputElement>(`[name="ClaimName"]`);
    let inputTelefoneDenunciante = formStepContainer?.querySelector<HTMLInputElement>(`[name="Phone"]`);
    let inputSexoDenunciante = formStepContainer?.querySelector<HTMLInputElement>(`[name="Gender"]`);
    let inputNascimentoDenunciante = formStepContainer?.querySelector<HTMLInputElement>(`[name="Birthday"]`);
    
    let inputConcelhoDenunciante = formStepContainer?.querySelector<HTMLInputElement>(`[name="Municipality"]`);
    //let inputZonaReclamente = formStepContainer?.querySelector<HTMLInputElement>(`[name="Region"]`);

    let inputReclamacao = formStepContainer?.querySelector<HTMLInputElement>(`[name="Resume"]`);

    let claimsInfoContainer = formStepContainer?.querySelector<HTMLDivElement>(".claims-info-container");
    let btnSaveClaimsTrackerInfo = formStepContainer?.querySelector<HTMLButtonElement>(".btn-save-claim-info");

    let jsonStringClaimsTrackerInfo = "";
    let jsonStringClaimsTrackerFileName = "";

    let btnPrev = modalDenuncia?.querySelector<HTMLButtonElement>(".btn-prev");
    let btnNext = modalDenuncia?.querySelector<HTMLButtonElement>(".btn-next");
    let btnSubmit = modalDenuncia?.querySelector<HTMLButtonElement>(".btn-submit");

    let objErrorFeedbackMsg:{[key:string]:()=>formErrorSpreadObj} = {
        "step-1":function(){
            let typeD = inputTypeDenuncia?.value ?? "";

            if(typeD=="1"){
                return {
                    ClaimName:(inputNomeDenunciate?.value??"").trim()==""?"Campo obrigatório":"",
                    Phone:(inputTelefoneDenunciante?.value??"").trim()==""?"Campo obrigatório":"",
                    Gender:(inputSexoDenunciante?.value??"").trim()==""?"Campo obrigatório":"",
                    Birthday:(inputNascimentoDenunciante?.value??"").trim()==""?"Campo obrigatório":"",
                }
            }

            return {
                ClaimName:"",
                Phone:"",
                Gender:"",
                Birthday:"",
            }
        }
    }

    let mapInputStepsGroup:{[key:string]:string} = {
        ClaimName:"step-1",
        Phone:"step-1",
        Gender:"step-1",
        Birthday:"step-1",
        Email:"step-1",
        TypeD:"step-2",
        Municipality:"step-2",
        Resume:"step-2",
        CreatorType:"step-2"
    }

    allElementWithModalDenunciaRef.forEach(elRefModalReclama=>{
        elRefModalReclama.addEventListener("click",()=>{
            let modalRef = showModal("modal-denuncia");
        
            let helpContainer = document.querySelector(".help-container") as HTMLDivElement;

            helpContainer?.classList.remove("show");


            if(inputTypeDenuncia)inputTypeDenuncia.value="1";//reclamação não anonima

            loaderFormSubmited?.classList.add("d-none");

            containerAnonymos?.classList.add("d-none");

            tabStepToShow("step-1",1);

            loadConcelhosInSelect();

            jsonStringClaimsTrackerFileName = "";
            jsonStringClaimsTrackerInfo = "";

            formStepContainer?.reset();
        });
    });

    modalDenuncia?.addEventListener("click",(e)=>{
        let elementClicked = e.target as HTMLElement;

        if(elementClicked.classList.contains("btn-anonymous")){

            containerAnonymos?.classList.remove("d-none");

           if(inputTypeDenuncia)inputTypeDenuncia.value = "2";//Reclamação anonima

           tabStepToShow("step-2",2);

        }else if(elementClicked.classList.contains("btn-set-info")){

           if(inputTypeDenuncia)inputTypeDenuncia.value = "1";//Reclamação não anonima

           containerAnonymos?.classList.add("d-none");

        }else if(elementClicked.classList.contains("step-btn")){
            let parentItemContainer = elementClicked.closest(".step-item-container");
            //let parentElement = parentItemContainer?.parentElement;

            let stepItem = parentItemContainer?.querySelector<HTMLElement>(".step-item");

            let stepToShow = stepItem?.dataset?.step ?? "";

            let stepProgress = +(stepToShow?.split("-")[1] ?? "0");

            tabStepToShow(stepToShow,stepProgress);
        }
    });

    btnNext?.addEventListener("click",()=>{
        let stepActive = modalDenuncia?.querySelector<HTMLElement>(".step-item.active");

        let stepProgress = +((stepActive?.dataset.step ?? "")?.split("-")[1] ?? "0");

        stepProgress = stepProgress>=2?2:(stepProgress+1);

        tabStepToShow("step-"+stepProgress,stepProgress);
    });

    btnPrev?.addEventListener("click",()=>{
        let stepActive = modalDenuncia?.querySelector<HTMLElement>(".step-item.active");

        let stepProgress = +((stepActive?.dataset.step ?? "")?.split("-")[1] ?? "0");

        stepProgress = stepProgress<=1?1:(stepProgress-1);

        tabStepToShow("step-"+stepProgress,stepProgress);
    });

    btnSubmit?.addEventListener("click",()=>{
        if((inputReclamacao?.value??"").trim()==""){
            displayErrorsFeedback({Resume:"Campo obrigatório"},formStepContainer!);
        }else if(previousErrorArr.length){
            let firstStepWitError = previousErrorArr[0];

            let stepProgress = +(firstStepWitError?.split("-")[1] ?? "0");

            let objError = objErrorFeedbackMsg[firstStepWitError]

            tabStepToShow(firstStepWitError,stepProgress);
            displayErrorsFeedback(objError(),formStepContainer!);
        }else{
            let formData = new FormData(formStepContainer!);

            let dateString = (formData.get("Birthday") ?? "") as string
            formData.set("Birthday",formatDateToSearch(new Date(dateString)));

            let [objError,inputsNameArr] = validateForm(formData);

            if(inputsNameArr.length){
                showStepOnErrorDetected(inputsNameArr);
                displayErrorsFeedback(objError,formStepContainer!);
            }else{
                submitFormCreateClaim(formData);
            }
        }
    });

    // inputConcelhoDenunciante?.addEventListener("change",(e)=>{
    //     let concelhoSelected = (inputConcelhoDenunciante?.value??"").trim();

    //     if(concelhoSelected){
    //         loaderFormSubmited?.classList.remove("d-none");

    //         httpRequest<string>(`/claims/list-zonas?c=${concelhoSelected}`,"GET")
    //         .then(data=>{
    //             let zonasList = data.result;

    //             if(zonasList && zonasList.length){
    //                 loadZonasInSelect(zonasList);
    //             }else{
    //                 swal("Ooops!!!","Não foi possivel carregar a lista de zonas","error");
    //             }

    //             loaderFormSubmited?.classList.add("d-none");
                
    //         }).catch(err=>{
    //             console.error(err);
    //             loaderFormSubmited?.classList.add("d-none");
    //         });
    //     }
    // });

    btnSaveClaimsTrackerInfo?.addEventListener("click",(e)=>{
        if(jsonStringClaimsTrackerInfo && jsonStringClaimsTrackerFileName){
            downloadTrackerInfoJson();

            jsonStringClaimsTrackerFileName = "";
            jsonStringClaimsTrackerInfo = "";

            containerAnonymos?.classList.add("d-none");
            if(inputTypeDenuncia)inputTypeDenuncia.value="1";//reclamação não anonima
            tabStepToShow("step-1",1);

            swal("Feito",`Informações de rastreo guardada na pasta de transferência deste dispositivo`,"success");
        }
    });

    function submitFormCreateClaim(formData:FormData){
        loaderFormSubmited?.classList.remove("d-none");

        httpRequest<IClaimsCreated>(
            "/csu-plataform/new-complaint",
            "POST",
            false,
            formData,
            formStepContainer!
        )
        .then(dataResp=>{
            
            let {TrackID,EditKey,CreationDate} = dataResp.result![0];

            if(TrackID.trim() && EditKey.trim() && CreationDate.trim()){
                jsonStringClaimsTrackerInfo = JSON.stringify(
                    {
                        TrackID:TrackID.trim(),
                        EditKey:EditKey.trim()
                    }
                );
                jsonStringClaimsTrackerFileName = "denuncia_"+formatDateToDisplayWithUnderscore(CreationDate)+".json";

                if(claimsInfoContainer){
                    claimsInfoContainer.innerHTML = `
                        <div class="container-info">
                            <span class="label-title">
                                ID de rastreo:
                            </span>
                            <span class="label-info">
                                ${TrackID.trim()}
                            </span>
                        </div>
                        <div class="container-info">
                            <span class="label-title">
                                Chave de acesso:
                            </span>
                            <span class="label-info">
                                ${EditKey.trim()}
                            </span>
                        </div>
                    `;
                }
                tabStepToShow("step-3",3);
            }else{
                swal("Processo enviado",`Sua denúncia foi enviada após análises e investigações retornaremos uma resposta sobre o assunto`,"success");
                tabStepToShow("step-1",1);
            }


            formStepContainer?.reset();
            loaderFormSubmited?.classList.add("d-none");
        })
        .catch(err=>{
            console.error(err);
            if(typeof err =="object" && ("errorFeedback" in err)){
                let dataRespError = err as IHttpRequestResponse<any>;

                let errorFeedback = dataRespError.errorFeedback;

                if(errorFeedback){
                    let arrInputsName = errorFeedback.map(err=>err.formControll);

                    badRequestHandle(dataRespError,formStepContainer!);
                    showStepOnErrorDetected(arrInputsName);
                }
            }
            loaderFormSubmited?.classList.add("d-none");
        });
    }

    function downloadTrackerInfoJson() {
        const a = document.createElement("a");
        const file = new Blob([jsonStringClaimsTrackerInfo], { type: "text/plain" });
        a.href = URL.createObjectURL(file);
        a.download = jsonStringClaimsTrackerFileName;
        a.click();
    }

    function formatDateToDisplayWithUnderscore(inputDate:string) {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();

        return `${day}_${month}_${year}`;
    }

    function tabStepToShow(stepToShow:string,stepNum:number=0){
        modalDenuncia?.querySelector(".step-item.active")?.classList.remove("active");
        let setpsWithError = modalDenuncia?.querySelectorAll(".step-item.error");
        let setpsWithCompleted = modalDenuncia?.querySelectorAll(".step-item.complete");
        modalDenuncia?.querySelector(`.step-item[data-step="${stepToShow}"]`)?.classList.add("active");

        modalDenuncia?.querySelectorAll(".step-tab").forEach(el=>{
            el.classList.add("d-none");
        });

        setpsWithError?.forEach(step=>{
            step.classList.remove("error")
        })

        setpsWithCompleted?.forEach(step=>{
            step.classList.remove("complete")
        })

        modalDenuncia?.querySelector("#"+stepToShow)?.classList.remove("d-none");

        showPrevNextBtn();

        stepsOkay(stepNum)
    }

    function showPrevNextBtn(){
        let stepActive = modalDenuncia?.querySelector<HTMLElement>(".step-item.active");

        let stepProgress = +((stepActive?.dataset.step ?? "")?.split("-")[1] ?? "0");

        console.log("stepProgress",stepProgress)

        btnNext?.classList.add("d-none");
        btnPrev?.classList.add("d-none");
        btnSubmit?.classList.add("d-none");

        if(stepProgress==2){
            btnNext?.classList.add("d-none");
            btnPrev?.classList.remove("d-none");
            btnSubmit?.classList.remove("d-none");
        }else if(stepProgress==1){
            btnNext?.classList.remove("d-none");
        }
    }

    function stepsOkay(stepProgress=0){

        previousOkayArr.length = 0;
        previousErrorArr.length = 0;
        
        if([2,3].includes(stepProgress)){
            let typeD = (inputTypeDenuncia?.value??"").trim();
            if(typeD=="2"){
                previousOkayArr.push("step-1")
            }else{
                if(
                    (inputNomeDenunciate?.value??"").trim() &&
                    (inputTelefoneDenunciante?.value??"").trim() &&
                    (inputSexoDenunciante?.value??"").trim() &&
                    (inputNascimentoDenunciante?.value??"").trim()
                ){
                    previousOkayArr.push("step-1")
                }else{
                    previousErrorArr.push("step-1")
                }
            }
        }
        // if([2,3].includes(stepProgress)){
            
        // }

        // if([4,5].includes(stepProgress)){
        //     let tipoRepresentante = (inputTypeRepresentante?.value??"").trim();

        //     if(tipoRepresentante && tipoRepresentante!="nenhum"){
        //         if(
        //             (inputNomeRepresentante?.value??"").trim() &&
        //             (inputTelefoneRepresentante?.value??"").trim() &&
        //             (inputSexoRepresentante?.value??"").trim() &&
        //             (inputNascimentoRepresentante?.value??"").trim()
        //         ){
        //             previousOkayArr.push("step-3")
        //         }else{
        //             previousErrorArr.push("step-3")
        //         }
        //     }else{
        //         previousOkayArr.push("step-3")
        //     }
        // }

        // if([3].includes(stepProgress)){
        //     if(
        //         (inputConcelhoDenunciante?.value??"").trim() &&
        //         (inputZonaReclamente?.value??"").trim()
        //     ){
        //         previousOkayArr.push("step-2")
        //     }else{
        //         previousErrorArr.push("step-2")
        //     }
        // }

        previousOkayArr.forEach(step=>{
            modalDenuncia?.querySelector(`.step-item[data-step="${step}"]`)?.classList.add("complete");
        });

        previousErrorArr.forEach(step=>{
            modalDenuncia?.querySelector(`.step-item[data-step="${step}"]`)?.classList.add("error");
        });
    }

    function loadConcelhosInSelect(){
        let optionsArr = [
            `
                <option value="" disabled selected>
                    Concelho da ocorrência
                </option>
            `
        ];
        concelhosListObjArr.forEach(el=>{
            optionsArr.push(`
                <option value="${el.value}">
                    ${el.label}
                </option>
            `)
        });

        if(inputConcelhoDenunciante)inputConcelhoDenunciante.innerHTML = optionsArr.join("");
    }

    // function loadZonasInSelect(zonalList:string[]){
    //     let listZonasOptions = [
    //         `
    //             <option value="" disabeld selected>
    //                 Zonas
    //             </option>
    //         `
    //     ];

    //     zonalList.forEach(zonas=>{
    //         listZonasOptions.push(`
    //             <option value="${zonas}">
    //                 ${zonas}
    //             </option>
    //         `);
    //     });

    //     if(inputZonaReclamente)inputZonaReclamente.innerHTML = listZonasOptions.join("");
    // }

    function validateForm(formData:FormData):[formErrorSpreadObj,string[]]{
        let formErrorFeedBack:formErrorSpreadObj = {};

        let ClaimName = (formData.get("ClaimName") as string)?.trim() ?? "";
        let Phone = ((formData.get("Phone") as string)?.trim() ?? "").split(" ").join("");
        let Email = (formData.get("Email") as string)?.trim() ?? undefined;
        let Birthday = (formData.get("Birthday") as string)?.trim() ?? "";
        let TypeD = (formData.get("TypeD") as string)?.trim() ?? "";
        let Gender = (formData.get("Gender") as string)?.trim() ?? "";
        let Municipality = ((formData.get("Municipality") as string)?.trim() ?? "");
        let Resume = (formData.get("Resume") as string)?.trim() ?? "";
        let CreatorType = +((formData.get("CreatorType") as string)?.trim() ?? "0");

        if(!["1","2"].includes(TypeD)){
            formErrorFeedBack["TypeD"] = "Tipo de denúncia não especificado";
        }else{

            if(TypeD=="1"){
                if(!ClaimName){
                    formErrorFeedBack["ClaimName"] = "Campo obrigatório";
                }

                if(!Phone){
                    formErrorFeedBack["Phone"] = "Campo obrigatório";
                }else if(Phone.length!=7){
                    formErrorFeedBack["Phone"] = "Escreva um numero de telefone válido";
                }else if(isNaN(+Phone) || (+Phone)===0){
                    formErrorFeedBack["Phone"] = "Escreva um numero de telefone válido";
                }
                // else if(!["5","9"].includes(Phone.split("")[0])){
                //     formErrorFeedBack["Phone"] = "Escreva um numero de telefone válido";
                // }

                if(!["M","F"].includes(Gender)){
                    formErrorFeedBack["Gender"] = "Sexo é obrigatório";
                }

                if(Email){
                    if(!regexEmail.test(Email)){
                        formErrorFeedBack["Email"] = "degite um email válido";
                    }
                }

                if(!isValidDateV2(Birthday)){
                    formErrorFeedBack["Birthday"] = "informe uma data válida";
                }
            }
        }

        if(!validsConcelhos.includes(Municipality)){
            formErrorFeedBack["Municipality"] = "informe um concelho válido";
        }

        if(Resume.length<10){
            formErrorFeedBack["Resume"] = "Descreva mais a sua reclamação";
        }

        if(![7,8,9].includes(CreatorType)){
            formErrorFeedBack["CreatorType"] = "Campo obrigatório";
        }

        return [formErrorFeedBack,Object.keys(formErrorFeedBack)];
    }

    function isValidDateV2(dateString:string) {
        // Regular expression to match "yyyy-mm-dd" format
        const regex = /^\d{4}-(0[1-9]|1[0-2])-([0-2]\d|3[01])$/;

        if (!regex.test(dateString)) {
            return false; // Not in the correct format
        }

        const [year, month, day] = dateString.split('-').map(Number);

        // Check if the month is valid
        if (month < 1 || month > 12) {
            return false;
        }

        // Check if the day is valid considering leap years
        const daysInMonth = new Date(year, month, 0).getDate();
        if (day < 1 || day > daysInMonth) {
            return false;
        }

        return true;
    }

    function formatDateToSearch(date:Date){
        date.setDate(date.getDate()+1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    }

    function showStepOnErrorDetected(inputsNameArr:string[]){
        let inputName = inputsNameArr[0]
        let firstStepWitError = mapInputStepsGroup[inputName];

        let stepProgress = +(firstStepWitError?.split("-")[1] ?? "0");

        tabStepToShow(firstStepWitError,stepProgress);
    }
}