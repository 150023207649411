import { showModal } from "./modalCtrl";

export function faqModalCtrl(){
    let allElementWithFaqModalRef = document.querySelectorAll<HTMLElement>(`[data-modal="modal-faq"]`);

    allElementWithFaqModalRef.forEach(elRefModalFaq=>{
        elRefModalFaq.addEventListener("click",()=>{
            let faqQuestion = +(elRefModalFaq.dataset?.faqQuestion ?? "");

            if(faqQuestion){
                let faqAnswer = faqArr.find(el=>el.ID==faqQuestion);

                if(faqAnswer){
                    let modalFAQ = showModal("modal-faq");

                    if(modalFAQ){
                        modalFAQ.querySelector<HTMLElement>(".modal-title")!.innerText = faqAnswer.title;

                        let answerArr = faqAnswer.answer;

                        if(Array.isArray(answerArr)){
                            let liList = answerArr.map(answer=>`
                                <li>${answer}</li>
                            `).join("");

                            modalFAQ.querySelector<HTMLElement>(".modal-body")!.innerHTML = `<ul>${liList}</ul>`
                        }else{
                            modalFAQ.querySelector<HTMLElement>(".modal-body")!.innerHTML = `
                                <p>${answerArr}</p>
                            `;
                        }
                    }
                }
            }
        });
    })
}

let faqArr = [
    {
        ID:1,
        title:"Qual a finalidade do Cadastro Social Único?",
        answer:[
            "Garante maior transparência, justiça social e igualdade de oportunidade na atribuição e gestão dos benefícios sociais. Facilita o conhecimento, a formulação e implementação de políticas públicas capazes de promover a melhoria de condições de vida dos agregados familiares;",
            "É a base de dados de referência para as Câmaras Municipais, as operadoras de água, de eletricidade, os Ministérios da Saúde, Educação, Habitação, Família, Desenvolvimento e Inclusão Social, o Centro Nacional de Pensões Sociais (CNPS), e outras entidades que executam/gerem programas de proteção social;",
            "Permite calcular o indicador de focalização que é um indicador de qualidade de vida dos agregados familiares e dos seus membros."
        ]
    },
    {
        ID:2,
        title:"Como cadastrar?",
        answer:[
            "O registo no Cadastro Social Único (CSU) é feito por visita domiciliar do Técnico do Cadastro Social Único, que se desloca à residência dos agregados familiares para a recolha de informações socioeconómicas.", 
            "Igualmente, pode ser realizado nas Câmaras Municipais, num primeiro momento, seguido de uma visita a domicílio para validação das informações."
        ]
    },
    {
        ID:3,
        title:"Quem pode ser cadastrado no CSU?",
        answer:[
            "Todo o cidadão cabo-verdiano pode, por iniciativa própria, promover o registo do seu agregado familiar no Cadastro Social Único, condição prévia e incontornável para o acesso a qualquer benefício social público ao nível da Rede de Segurança.",
            "<b>Por exemplo</b>: Para beneficiar das tarifas sociais de água ou energia, apoio de transporte escolar, kit escolar, propinas do ensino superior, isenção da taxa moderadora nos serviços de saúde, o agregado familiar deve estar previamente inscrito no CSU e, dependendo dos critérios utilizados por cada programa, será ou não considerado elegível."
        ]
    },
    {
        ID:4,
        title:"Gestão do Cadastro Social Único",
        answer:"O Sistema do CSU é administrado e monitorado nos serviços centrais do MFIDS através da Coordenação Nacional, em articulação com os serviços Locais do CSU das Câmaras Municipais, conforme estabelece o referido no Decreto Regulamentar nº 7/2018, de 20 de setembro de 2018."
    },
    {
        ID:5,
        title:"Como consultar ou atualizar seus dados no Cadastro Social Único?",
        answer:"Os indivíduos inscritos devem dirigir-se às Câmaras Municipais do seu concelho para consultas ou atualização dos dados."
    },
    {
        ID:6,
        title:"Proteção de dados pessoais",
        answer:[
            "Todos os dados fornecidos no âmbito do Cadastro Social Único são de caráter confidencial e serão utilizados exclusivamente para fins de apoios sociais e estatísticos. Por consequência, todas as pessoas que intervêm na recolha e tratamento desses dados estão obrigadas a respeitar o segredo da informação e sigilo profissional, tal como descrito abaixo:",
            "A informação declarada pelos indivíduos é confidencial (não deve ser comunicada a outrem);",
            "A informação recolhida só pode ser vista pelo supervisor e outros técnicos ligados ao CSU;",
            "Portanto, o uso dos dados do CSU é regido pelos princípios do respeito da dignidade e da privacidade dos indivíduos cadastrados e a sua utilização indevida acarreta a aplicação de sanções previstas nos termos da lei."
        ]
    }
]