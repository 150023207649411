import swal from "sweetalert";
import { httpRequest } from "./HttpRequest";

export function contactFormSubmit(){
    let formContact = document.querySelector(".form-contact") as HTMLFormElement;
    let loaderContainer = formContact.querySelector(".loader-container") as HTMLDivElement;

    formContact.addEventListener("submit",(e)=>{
        e.preventDefault();

        let formData = new FormData(formContact);

        //@ts-expect-error
        let recaptch = (grecaptcha?.getResponse()??"");

        if(recaptch){
            loaderContainer.classList.remove("d-none");
            httpRequest("/csu-plataform/contact","POST",false,formData,formContact)
            .then(resp=>{
                if(resp.status==200){
                    swal("Mensagem enviada",resp.message,"success");
                    formContact.reset();
                }

                loaderContainer.classList.add("d-none");
            })
            .catch(e=>{
                console.log("error form contact",e);
                loaderContainer.classList.add("d-none");
            });
        }else{
            swal("Ooops!!!","Tem de realizar a verificação captch primeiro","warning");
        }
    });
}