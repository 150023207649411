import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { responsiveMenuOpen } from './features/responsiveMenuOpen';
import { counterIndicator } from './features/counterIndicator';
import { AddGlobalOnClose, directLinkFormOpenCtrl } from './features/modalCtrl';
import { faqModalCtrl } from './features/faqModalCtrl';
import { onWindowScroll, scrollToTopFeature } from './features/scrollToTop';
import { onSupportLinkClicked } from './features/supportLinkClicked';
import { contactFormSubmit } from './features/contactForm';
import { reclamaModalCtrl } from './features/reclamaModalCtrl';
import { denunciaModalCtrl } from './features/denunciaModalCtrl';
//import { partnerSlider } from './features/partnersSlider';

function main(){
    AOS.init();
    responsiveMenuOpen();
    counterIndicator();

    AddGlobalOnClose();
    faqModalCtrl();
    reclamaModalCtrl();
    denunciaModalCtrl();
    scrollToTopFeature();
    onWindowScroll();
    onSupportLinkClicked();
    //partnerSlider();

    contactFormSubmit();

    directLinkFormOpenCtrl();
}

main()