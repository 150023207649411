import { isInViewport, isInViewport2, isInViewport3 } from "./counterIndicator";

export function scrollToTopFeature(){
    let scrollToTopBtn = document.querySelector(".scroll-to-op") as HTMLDivElement;

    scrollToTopBtn.addEventListener("click",()=>{
        window.scrollTo({
            top: 0,
            left: 100,
            behavior: "smooth",
        });
    });
}

export function onWindowScroll(){
    showAddBactToTopBtn();
    window.addEventListener("scroll",showAddBactToTopBtn);
}

async function showAddBactToTopBtn(){
    // if(!isInViewport2(document.querySelector<HTMLElement>("#header-banner")!)){
    if(window.scrollY>0){
        document.querySelector(".scroll-to-op")?.classList.remove("d-none");
    }else{
        document.querySelector(".scroll-to-op")?.classList.add("d-none");
    }
}