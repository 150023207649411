import { httpRequest } from "./HttpRequest";

export interface IInfoCSU {
  concelhos: number
  agregados: number
  membros: number
  pobres: number
}

export function counterIndicator(){
    const indicatorSection = document.querySelector("#indicator") as HTMLElement;
    const counters = document.querySelectorAll<HTMLElement>('.total-label');

    httpRequest<IInfoCSU>("/csu-plataform/info-csu","GET")
    .then(resp=>{
      console.log("resp http",resp.result)
      if(Array.isArray(resp.result)){
        let {concelhos,agregados,membros,pobres} = resp.result[0];

        counters.forEach(counter => {
            let dataInfo = counter.dataset?.info??"";
            console.log("dataInfo",dataInfo)
            if(dataInfo=="concelhos") counter.dataset.count = ""+concelhos
            if(dataInfo=="agregados") counter.dataset.count = ""+agregados
            if(dataInfo=="membros") counter.dataset.count = ""+membros
            if(dataInfo=="pobres") counter.dataset.count = ""+pobres

            updateCounter(counter,indicatorSection);
        });
      }
    })
}

function updateCounter(counter:HTMLElement,indicatorSection:HTMLElement) {
    const target = +(counter.getAttribute('data-count')??0);
    let count = +counter.innerText;

    //const inc = Math.floor((target - count) / 100);

    let idInterval = setInterval(()=>{
        if(isInViewport(indicatorSection)){
            count+=1678;
            if (count < target) {
                counter.innerText = formatNumberWithDecimal(count);
                // repeat the function
                //setTimeout(updateCounter,500);
            }
            // if the count not equal to target, then add remaining count
            else {
                counter.innerText = formatNumberWithDecimal(target);
                clearInterval(idInterval);
            }
        }
    },2);
}

export function isInViewport2(el:HTMLElement) {
    // console.log("innerHeight",(window.innerHeight || document.documentElement.clientHeight))
    // console.log("innerWidth",(window.innerWidth || document.documentElement.clientWidth))

    const rect = el.getBoundingClientRect();

    // console.log("rect",rect.top,rect.left)
    // console.log("rect",rect.bottom,rect.right)

    console.log("scrollY",window.scrollY)

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        (rect.bottom-120) <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
}

export function isInViewport3(el: HTMLElement):Promise<boolean> {
  const options = {
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No margin
    threshold: 0 // Element is considered in viewport as soon as a single pixel is visible
  };

  return new Promise((resolve,reject)=>{
     const observer = new IntersectionObserver(entries => {
      // Check if the element is in the viewport
      if (entries[0].isIntersecting) {
        // Element is in the viewport
        // Do something here
        resolve(true)
      } else {
        // Element is not in the viewport
        // Do something else
        resolve(false)
      }
    }, options);

    observer.observe(el);
  });

 

  
}

function isInViewport4(element:HTMLElement) {
  var rect = element.getBoundingClientRect();
  var html = document.documentElement;
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
}

export function isInViewport(el:HTMLElement) {
    var bounds = el.getBoundingClientRect();
    return bounds.top < window.innerHeight && bounds.bottom > 0;
}

function formatNumberWithDecimal(number:number) {
  const formattedNumber = number.toLocaleString('pt-PT');
  return ""+formattedNumber;
}